import React from "react";

const InnerContactInfo = () => {
  const info_items = [
    {
      src: "/img/icon/loction_icon03.png",
      title: "Address",
      desc: (
        <>
          <p>Office Address: Suite B-09,<br />
            Plot: H-161, BSI Business Park,<br /> Sector-63, Noida (U.P) 201301</p>
        </>
      ),
    },
    {
      src: "/img/icon/mail_icon03.png",
      title: "Email",
      desc: (
        <>
      <a href="tel:0123456789">hello@kodeklan.com</a>
        </>
      ),
    },
    {
      src: "/img/icon/phone_icon03.png",
      title: "Phone",
      desc: (
        <>
        <a href="mailto:your@email.com">+91-120 477 4269</a>
        </>
      ),
    },
  ];

  return (
    <ul className="list-wrap">
      {info_items.map((x, index) => (
        <li key={index}>
          <div className="contact-info-item">
            <div className="icon">
              <img src={x.src} alt="" />
            </div>
            <div className="content">
              <h6 className="title">{x.title}</h6>
              {x.desc}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default InnerContactInfo;

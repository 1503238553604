import React from "react";
import { useLocation } from "react-router-dom";

const CommunityArea = () => {
  const location = useLocation();
  const route = location.pathname;

  console.log("hello");

  return (
    <div className="community-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <div className="community-img">
              <img
                src="/img/team/team.png"
                alt=""
              />
            </div>
            <div className="community-content">
              <p style={{textAlign: "left"}}>
               <h4> Meet the Team – The Power Behind KodeKlan</h4>
                At KodeKlan, our story is one of growth, passion, and unwavering
                commitment. What began as a one-person venture has evolved into
                a thriving team of over 30 dedicated IT professionals, each
                bringing their unique expertise and drive to every project.
                Every team member at KodeKlan is not only highly skilled but
                embodies the dedication of a full team, delivering results that
                are truly impactful.
                <br />
                Our team specializes in a broad range of services, from web and
                software development to app creation, IT consulting, cloud
                solutions, and digital marketing. We’re here to turn your ideas
                into powerful digital realities, crafting solutions that are
                both innovative and tailored to meet your needs. With us, you’re
                not just getting technical expertise; you’re gaining a committed
                partner who is invested in your success.
                <br />
                <br />
                <h4> Why We Stand Out</h4>
             
                Our team's dedication is unmatched. We take pride in our
                collaborative approach, which allows us to bring out the best in
                each project. Together, we focus on understanding your goals and
                delivering outcomes that exceed expectations, bringing your
                dreams to life through digital transformation.
                <br />
                Discover how KodeKlan's dedicated team can make a difference in
                your business journey. We’re excited to work with you and
                achieve great things together.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityArea;

import React from "react";

const ServicesDetailsArea = () => {
  return (
    <div className="services-details-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <div className="services-details-img text-center">
              <img src="/img/images/services.png" alt="" />
            </div>

            <div className="services-details-content text-center">
              <p>
              We believe that Your idea deserves the best digital experience. That’s why we offer tailored development services designed specifically for you—whether it’s a dynamic mobile app, a powerful software solution, or an eye-catching website that’s optimized for search engines. But we don’t stop there. Our secure cloud storage solutions and expert IT consulting are crafted to support your unique business needs and empower you to reach new heights. We’re not just your service provider we’re your partners in success. Let’s work together to bring your ideas to life—contact us today, and let’s embark on this exciting journey together!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="services-details-shape">
        <img src="/img/images/services_details_shape.png" alt="" />
      </div>
    </div>
  );
};

export default ServicesDetailsArea;

import React from "react";
const Policies = () => {
  return (
    <section className="blog-details-area pb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="blog-details-wrap">
              <div className="bd-content-bottom">
                <h5 className="">
                  1. Introduction
                </h5>
                <p>
                  Welcome to Kodeklan. We are committed to protecting your personal information and ensuring your experience with us is safe and secure. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you visit our website www.kodeklan.com  and use our services.
                </p>
                <h5 className="">
                  2. Information We Collect
                </h5>
                <ul className="list-wrap">
                  <li>
                    <span>2.1 Personal Information</span>
                    When you visit our Site or use our services, we may collect personal information that you voluntarily provide to us, such as:
                    Name
                    Email address
                    Phone number
                    Company name (if applicable)
                  </li>
                  <br />
                  <li>
                    <span>2.2 Non-Personal Information</span>
                    We may also collect non-personal information about your visit, including:
                    IP address
                    Browser type
                    Operating system
                    Pages viewed
                    Time spent on the Site
                    Referring website
                  </li>
                </ul>
                <h5 className="">
                  3. How We Use Your Information
                </h5>
                <p>We use the information we collect for the following purposes:
                  To provide, maintain, and improve our services
                  To communicate with you, including responding to your inquiries and sending updates
                  To personalize your experience on our Site
                  To analyze usage and trends to enhance our Site and services
                  To comply with legal obligations and protect our rights</p>
                <h5>
                  4. How We Share Your Information
                </h5>
                <p>
                  We do not sell, trade, or otherwise transfer your personal information to outside parties, except in the following circumstances:
                  Service Providers: We may share information with trusted third-party service providers who assist us in operating our Site or conducting our business, provided they agree to keep your information confidential.
                  Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities.
                  Business Transfers: In the event of a merger, acquisition, or any form of sale of some or all of our assets, your information may be transferred as part of that transaction.
                </p>
                <h5>
                  5. Cookies and Tracking Technologies
                </h5>
                <p>
                  We use cookies and similar tracking technologies to enhance your experience on our Site. Cookies are small files stored on your device that help us understand your preferences and track usage patterns. You can control the use of cookies through your browser settings.
                </p>
                <h5>
                  6. Data Security
                </h5>
                <p>
                  We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
                </p>
                <h5>
                  7. Your Choices and Rights
                </h5>
                <p>
                  You have the right to:
                  Access and update your personal information
                  Request the deletion of your personal information
                  Opt-out of receiving promotional communications from us
                  To exercise these rights, please contact us at contact@kodeklan.com.
                </p>
                <h5>
                  8. Third-Party Links
                </h5>
                <p>
                  Our Site may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to review their privacy policies before providing any personal information.
                </p>
                <h5>
                  9. Children's Privacy
                </h5>
                <p>
                  Our Site is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.
                </p>
                <h5>
                  10. Changes to This Privacy Policy
                </h5>
                <p>
                  We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
                </p>
                <h5>

                  11. Contact Us
                </h5>
                <p>

                  If you have any questions or concerns about this Privacy Policy, please contact us at:

                  Kodeklan

                  Email: hello@kodeklan.com

                  Address: suite: B-09, Plot : H-161, BSI Business Park, Sector-63, Noida (U.P) 201301
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Policies;

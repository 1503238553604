import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

export default function SoftwareCard() {
  React.useEffect(() => {
    AOS.init({
      duration: 3000, // Duration of animations
      once: false, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const cardStyle = {
    border: "1px solid #ccc", // Light gray border
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Light shadow
    padding: "20px",
    borderRadius: "8px", // Optional rounded corners
    transition: "box-shadow 0.3s ease", // Smooth shadow transition
    textAlign: "center", // Center align content
    backgroundColor: "#fff", // White background
    height: "360px",
  };

  const cardHoverStyle = {
    boxShadow: "0 6px 16px rgba(0, 0, 0, 0.2)", // Stronger shadow on hover
  };

  const imgStyle = {
    // width: '20%',

    height: "100px",
    objectFit: "cover", // Ensure images are resized correctly
    marginBottom: "15px", // Add space below the image
  };

  return (
    <>
      <section className="overflow-hidden">
        <div className="container mb-100">
          <div className="row justify-content-center g-4">
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div
                className="color1"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/softwareDevCards/erp_11631991.png"
                  height="100px"
                  alt=""
                />
                <div className="">
                  <h4>ERP Software Development</h4>
                  <p>
                    Our ERP results integrate business processes across
                    departments, enhancing collaboration and data visibility. We
                    streamline workflows in finance, HR, and force chain,
                    barring silos for bettered effectiveness.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="color2"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                <img
                  src="/img/softwareDevCards/CRM.png"
                  height="100px"
                  alt=""
                />
                <div className="textcont">
                  <h4>Custom CRM Solutions</h4>
                  <p>
                    Enhance customer interactions with our tailored CRM systems.
                    Designed for your organization, these solutions improve data
                    management and client relationships, helping your team
                    nurture customer loyalty.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>

            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color4"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/softwareDevCards/lot development.png"
                  height="100px"
                  alt=""
                />
                <div className="">
                  <h4>Lot Development</h4>
                  <p>
                    Our software results for lot development manage large data
                    volumes and processes seamlessly. We give tools to
                    streamline operations, enhancing data delicacy and icing
                    effective association.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <div
                className="color5"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/softwareDevCards/business intelegence tool.png"
                  height="100px"
                  alt=""
                />
                <div className="">
                  <h4>Business Intelligence Tools</h4>
                  <p>
                    Unleash your data’s eventuality with our custom business
                    intelligence tools. We produce dashboards and analytics that
                    deliver practicable perceptivity, enabling informed,
                    strategic opinions for business growth.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>
            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <div
                className="color6"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/softwareDevCards/Software Maintenance and Upgrades.png"
                  height="100px"
                  alt=""
                />
                <div className="textcont">
                  <h4>Software Maintenance and Upgrades</h4>
                  <p>
                    We offer ongoing support to keep your software current and
                    functional. Our maintenance services include regular updates
                    and security enhancements, allowing you to focus on your
                    core business.
                  </p>
                  {/* <a href="javascript:;">Read More</a> */}
                </div>
              </div>
            </div>

            <div
              className="col-sm-4 col-xs-12 aos-init"
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <div
                className="color7"
                style={cardStyle}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow)
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.boxShadow = cardStyle.boxShadow)
                }
              >
                {" "}
                <img
                  src="/img/softwareDevCards/partner with us for succes.png"
                  height="100px"
                  alt=""
                />
                <div className="">
                  <h4>Partner with Us for Success</h4>
                  <p>
                    Join us in erecting sophisticated software results that
                    align with your pretensions. Together, we can produce
                    innovative tools that empower your association to thrive in
                    a dynamic digital geography.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

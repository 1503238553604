import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles

export default function MobileDevelopment() {
  React.useEffect(() => {
    AOS.init({
      duration: 3000, // Duration of animations
      once: false, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const cardStyle = {
    border: '1px solid #ccc',  // Light gray border
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',  // Light shadow
    padding: '20px',
    borderRadius: '8px',  // Optional rounded corners
    transition: 'box-shadow 0.3s ease',  // Smooth shadow transition
    textAlign: 'center', // Center align content
    backgroundColor: '#fff', // White background
    height:'360px'
};

const cardHoverStyle = {
    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',  // Stronger shadow on hover
};

const imgStyle = {
    // width: '20%',

    height: '100px',
    objectFit: 'cover', // Ensure images are resized correctly
    marginBottom: '15px', // Add space below the image
};

  return (
    <>
      <section className="overflow-hidden">
        <div className="">
          <div className="container mb-100">
            <div className="row justify-content-center g-4" >
              <div
                className="col-sm-4 col-xs-12 aos-init"
                data-aos="fade-right"
                data-aos-duration="3000"
              >
                <div className="color1" 
                 style={cardStyle}
                 onMouseEnter={e => e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow}
                 onMouseLeave={e => e.currentTarget.style.boxShadow = cardStyle.boxShadow}>
                  
                    {" "}
                    <img
                      src="/img/mobileDevCards/iOS App Development.png"
                      height="100px"
                      alt=""
                    />
                  
                  <div className="">
                    <h4>iOS App Development</h4>
                    <p>
                      We craft high-quality applications for iPhone and iPad,
                      utilizing the latest technologies to meet Apple's
                      guidelines. Our focus on performance optimization ensures
                      your app stands out in the App Store.
                    </p>
                    {/* <a href="">Read More</a> */}
                  </div>
                </div>
              </div>
              <div
                className="col-sm-4 col-xs-12 aos-init"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="color2"  style={cardStyle}
                                onMouseEnter={e => e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow}
                                onMouseLeave={e => e.currentTarget.style.boxShadow = cardStyle.boxShadow}>
                  
                    {" "}
                    <img
                      src="/img/mobileDevCards/app development.png"
                      height="100px"
                      alt=""
                    />
                  
                  <div className="textcont">
                    <h4>Android App Development</h4>
                    <p>
                      Our Android app development services create applications
                      that perform flawlessly on various devices. We leverage
                      the diverse Android ecosystem to deliver scalable,
                      high-performing apps for your target audience.
                    </p>
                    {/* <a href="javascript:;">Read More</a> */}
                  </div>
                </div>
              </div>

              <div
                className="col-sm-4 col-xs-12 aos-init"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <div className="color4"  style={cardStyle}
                                onMouseEnter={e => e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow}
                                onMouseLeave={e => e.currentTarget.style.boxShadow = cardStyle.boxShadow}>
                  
                    {" "}
                    <img
                      src="/img/mobileDevCards/Cross-Platform App Development.png"
                      height="100px"
                      alt=""
                    />
                  
                  <div className="">
                    <h4>Cross-Platform App Development</h4>
                    <p>
                      Reach a wider audience with our cross-platform solutions,
                      allowing seamless function across multiple operating
                      systems. This approach saves time and resources while
                      maintaining a consistent user experience.
                    </p>
                    {/* <a href="javascript:;">Read More</a> */}
                  </div>
                </div>
              </div>
              <div
                className="col-sm-4 col-xs-12 aos-init"
                data-aos="fade-right"
                data-aos-duration="3000"
              >
                <div className="color5"  style={cardStyle}
                                onMouseEnter={e => e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow}
                                onMouseLeave={e => e.currentTarget.style.boxShadow = cardStyle.boxShadow}>
                  
                    {" "}
                    <img
                      src="/img/mobileDevCards/App Design and UX &UI.png"
                      height="100px"
                      alt=""
                    />
                  
                  <div className="">
                    <h4>App Design and UX/UI</h4>
                    <p>
                      User engagement starts with captivating design. Our expert
                      designers create user-friendly, visually appealing
                      interfaces that enhance usability, fostering long-term
                      relationships with your brand.
                    </p>
                    {/* <a href="javascript:;">Read More</a> */}
                  </div>
                </div>
              </div>
              <div
                className="col-sm-4 col-xs-12 aos-init"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <div className="color6"  style={cardStyle}
                                onMouseEnter={e => e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow}
                                onMouseLeave={e => e.currentTarget.style.boxShadow = cardStyle.boxShadow}>
                  
                    {" "}
                    <img
                      src="/img/mobileDevCards/App Maintenance and Updates.png"
                      height="100px"
                      alt=""
                    />
                  
                  <div className="textcont">
                    <h4>App Maintenance and Updates</h4>
                    <p>
                      Launching your app is just the beginning. We offer ongoing
                      maintenance and updates to keep your application
                      functional, secure, and competitive in a changing market.
                    </p>
                    {/* <a href="javascript:;">Read More</a> */}
                  </div>
                </div>
              </div>

              <div
                className="col-sm-4 col-xs-12 aos-init"
                data-aos="fade-left"
                data-aos-duration="3000"
              >
                <div className="color7"  style={cardStyle}
                                onMouseEnter={e => e.currentTarget.style.boxShadow = cardHoverStyle.boxShadow}
                                onMouseLeave={e => e.currentTarget.style.boxShadow = cardStyle.boxShadow}>
                  
                    {" "}
                    <img
                      src="/img/mobileDevCards/From Concept to Deployment.png"
                      height="100px"
                      alt=""
                    />
                  
                  <div className="">
                    <h4>From Concept to Deployment</h4>
                    <p>
                      We guide you through every step of the mobile app
                      development process, from concept to deployment. Partner
                      with us to unlock mobile technology's potential and
                      elevate your brand.
                    </p>
                    {/* <a href="javascript:;">Read More</a> */}
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-4 col-xs-12 aos-init" data-aos="fade-up" data-aos-duration="3000">
                                <div className="color8">
                                    <img src="" width="100%" alt="" />
                                    <div className="textcont">
                                        <h4>Contests And Sweepstakes</h4>
                                        <p>Sweepstakes is a kind of contest, where awards to given to a winner. By following the same, the entrepreneur offers prices and awards to the winner,
                                        </p>
                                        <a href="javascript:;">Read More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 col-xs-12 aos-init" data-aos="fade-left" data-aos-duration="3000">
                                <div className="color8">
                                    <img src="" width="100%" alt="" />
                                    <div className="textcont">
                                        <h4>Social App Development</h4>
                                        <p>A social Media app is a computer-based technology, which helps your consumers in a more channelized manner. Social Media facilitates in the development
                                        </p>
                                        <a href="javascript:;">Read More</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4 col-xs-12 aos-init" data-aos="fade-up" data-aos-duration="3000">
                                <div className="color3">
                                    <img src="" width="100%" alt="" />
                                    <div className="textcont">
                                        <h4>Advertising</h4>
                                        <p>Advertising is an activity for promoting commercial products and services. Planning to throw an event? Just quickly let us know and we will handle the rest.
                                        </p>
                                        <a href="">Read More</a>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";

const TeamArea = () => {
  return (
    <section className="team-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="team-img-wrap">
              <img src="/img/team/employ.png" alt="" className="img-shape" />
              {/* <img src="/img/team/team_img01.png" alt="" className="img-one" /> */}
              {/* <img src="/img/team/team_img02.png" alt="" className="img-two" /> */}
              <img
                // src="/img/team/team_img03.png"
                alt=""
                className="img-three"
              />
              {/* <img src="/img/team/team_img04.png" alt="" className="img-four" /> */}
              {/* <img src="/img/team/team_img05.png" alt="" className="img-five" /> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="team-content">
              <div className="section-title mb-25">
                <span className="sub-title">Our Team</span>
                <h3 className="title">
                  Power Behind KodeKlan
                </h3>
              </div>
              <p>
                At KodeKlan, our story is one of growth, passion, and unwavering
                commitment. What began as a one-person venture has evolved into
                a thriving team of over 30 dedicated IT professionals, each
                bringing their unique expertise and drive to every project.
                Every team member at KodeKlan is not only highly skilled but
                embodies the dedication of a full team, delivering results that
                are truly impactful.
              </p>
              <Link to="/team" className="btn">
                Meet Our Team <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="team-shape-wrap">
        <img
          src="/img/images/team_shape01.png"
          alt=""
          className="ribbonRotate"
        />
        <img
          src="/img/images/team_shape02.png"
          alt=""
          className="float-bob-x"
        />
      </div>
    </section>
  );
};

export default TeamArea;

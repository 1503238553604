import React from "react";
import { Link } from "react-router-dom";

const ProjectFour = () => {
  return (
    <section className="about-area pt-100">
      <div className="container custom-container">
        <div className="about-inner">
          <div className="row align-items-center justify-content-center">
            <div className="col-46 order-0 order-lg-2">
              <div className="about-img text-end">
                <img src="/img/project/project_img02.png" alt="" />
              </div>
            </div>
            <div className="col-54">
              <div className="about-content">
                <div className="section-title mb-25">
                  <span className="sub-title">Green Farm Restaurant</span>
                  (Food Industry)
                </div>
                <p>
                  We are proud to have partnered with Green Farm, a prominent
                  Australian company, on several key projects. Our collaboration
                  included the design and development of two distinct websites
                  tailored to their unique needs:
                </p>

                <span>Restaurant CRM:</span>
                <p>
                  Additionally, we developed a dedicated website for their
                  restaurant, focusing on aesthetics and functionality to
                  attract and engage customers.
                </p>
                <span>Web Based Custom Point of Sale (POS) System:</span>
                <p>
                  To further streamline their operations, we implemented a
                  custom POS system that enhances efficiency and improves the
                  overall customer experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectFour;
